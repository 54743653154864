import PaymentPage from "./PaymentPage";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../constants/Api";

export default function MagnatiPage(props) {
  const params = new URLSearchParams(props.location.search);
  const ref = params.get("reference");
  const [status, setStatus] = useState("PENDING");
  const [orderId, setOrderId] = useState("");

  // Get order status
  useEffect(() => {
    if (ref) {
      axios
        .post(`${APP_URL}/en/magnati/status/${ref}/`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setStatus(response.data.status);
            setOrderId(response.data.orderId);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
  }, [ref]);

  return <PaymentPage status={status} reference={orderId} />;
}
