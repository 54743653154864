import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import WelcomeStyle from "./WelcomeStyle.js";
import firebase from "../../constants/Firebase";
import emenuLogo from "../../assets/img/emenu/emenu-logo.jpg";
import bookDemo from "../../assets/img/emenu/book-demo.webp";
import emenuBg from "../../assets/img/emenu/emenu-bg.jpg";

const useStyles = makeStyles(WelcomeStyle);

export default function WelcomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    firebase.analytics().logEvent("welcome_page_view");
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div
        style={{
          backgroundImage: `url("${emenuBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }}
      />
      <a href="https://www.digitalemenu.com" title={"digitalemenu.com"}>
        <img
          src={emenuLogo}
          className={classes.logoImage}
          alt={"Digital eMenu"}
          width={"900px"}
          height={"300px"}
        />
      </a>
      <a
        href="https://www.digitalemenu.com/contact"
        title={"digitalemenu.com/contact"}
      >
        <img
          src={bookDemo}
          className={classes.image}
          alt={"Book a Demo"}
          width={"480px"}
          height={"900px"}
        />
      </a>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left} />
            <div className={classes.pullCenter}>
              <a
                href="https://www.digitalemenu.com"
                target="_blank"
                className={classes.footerBrand}
                rel="noreferrer"
              >
                Digital eMenu
              </a>
              <br />
              <a
                href={"tel:97143793455"}
                target={"_blank"}
                className={classes.footerLink}
                rel="noreferrer"
              >
                +971 4 3793455
              </a>
              <br />
              <a
                href={"mailto:info@digitalemenu.com"}
                target={"_blank"}
                className={classes.footerLink}
                rel="noreferrer"
              >
                info@digitalemenu.com
              </a>
              <br />
              <a
                href={"https://www.digitalemenu.com"}
                target={"_blank"}
                className={classes.footerLink}
                rel="noreferrer"
              >
                www.digitalemenu.com
              </a>
              <ul className={classes.socialLinks}>
                <li>
                  <Button
                    href="https://facebook.com/digitalemenu"
                    target="_blank"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://instagram.com/digitalemenu"
                    target="_blank"
                    color="instagram"
                    justIcon
                    simple
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
